import React from 'react';
import {Link} from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const PhotographyPage = () => (
  <Layout>
    <SEO title="Photography" />
    <h2>Photography</h2>
    <p>
        Photography stuff goes here...
    </p>
  </Layout>
);

export default PhotographyPage;
